<div class="more-then-section">
  <section>
    <div>selfcv</div>

    <h2 [innerHTML]="'home_page.more_than_builder' | translate"></h2>

    <a class="sf-btn-primary" [routerLink]="appStateService.route_paths | lngRoute: 'cv_templates' : appStateService.dateTime">
      {{'home_page.get_started' | translate}}
    </a>
  </section>
</div>
